define("@ember/-internals/runtime/lib/ext/rsvp", ["exports", "rsvp", "@ember/runloop", "@ember/-internals/error-handling", "@ember/debug"], function (_exports, RSVP, _runloop, _errorHandling, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.onerrorDefault = onerrorDefault;
  RSVP.configure('async', (callback, promise) => {
    _runloop._backburner.schedule('actions', null, callback, promise);
  });
  RSVP.configure('after', cb => {
    _runloop._backburner.schedule(_runloop._rsvpErrorQueue, null, cb);
  });
  RSVP.on('error', onerrorDefault);
  function onerrorDefault(reason) {
    let error = errorFor(reason);
    if (error) {
      let overrideDispatch = (0, _errorHandling.getDispatchOverride)();
      if (overrideDispatch) {
        overrideDispatch(error);
      } else {
        throw error;
      }
    }
  }
  function errorFor(reason) {
    if (!reason) return;
    let withErrorThrown = reason;
    if (withErrorThrown.errorThrown) {
      return unwrapErrorThrown(withErrorThrown);
    }
    let withName = reason;
    if (withName.name === 'UnrecognizedURLError') {
      (false && !(false) && (0, _debug.assert)(`The URL '${withName.message}' did not match any routes in your application`, false)); // @ts-expect-error We'll hit this if the assert is stripped
      return;
    }
    if (reason.name === 'TransitionAborted') {
      return;
    }
    return reason;
  }
  function unwrapErrorThrown(reason) {
    let error = reason.errorThrown;
    if (typeof error === 'string') {
      error = new Error(error);
    }
    Object.defineProperty(error, '__reason_with_error_thrown__', {
      value: reason,
      enumerable: false
    });
    return error;
  }
  var _default = _exports.default = RSVP;
});
